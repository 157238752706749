'use client';
import { ReactNode, useEffect, useState } from 'react';

export const ShowAfterScroll = ({ children }: { children: ReactNode }) => {
  const [isScrolled, setIsScrolled] = useState(false);

  const handleScroll = () => {
    const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
    setIsScrolled(scrollTop > 0);
    document?.removeEventListener('scroll', handleScroll);
  };

  useEffect(() => {
    document.addEventListener('scroll', handleScroll);

    return () => document?.removeEventListener('scroll', handleScroll);
  }, []);

  return <>{isScrolled && children}</>;
};
